@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary-color-100: #fc2947;
  --primary-color-200: #393e46;
  --secondary-color-100: #a9a8a8;
  --secondary-color-110: #d9d9d9;
  --secondary-color-120: #f7f7f7;
  --secondary-color-130: #f7f7f7b2;
  --secondary-color-200: #ffffff;
  --secondary-color-300: #ca2139;
  --secondary-color-400: #f9f5eb;
  --success-color-100: #82cd47;
  --warning-color-100: #ff3333;
  --warning-color-200: #ff6000;
  --neutral-color-300: #4285f4;
  --neutral-color-200: #878787;
  --neutral-color-100: #393e46;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Roboto, sans-serif;
  padding: 0px;
  margin: 0px;
}

a {
  color: var(--neutral-color-100);
  text-decoration: unset;
}

a:hover {
  text-decoration: unset;
}

button {
  font-family: Roboto, sans-serif;
  cursor: pointer;
}

input,
select {
  font-size: 16px;
  outline: unset !important;
}

.w100 {
  width: 100% !important;
}

.text-align-right {
  text-align: right;
}

.btn-primary {
  font-size: 18px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border: 1px solid #000;
  transition: all 0.3s;
}

.btn-primary:hover {
  background-color: #fc2947;
  border: 1px solid #fc2947;
}

.btn-primary.btn-rounded {
  border-radius: 1000px;
}

.btn-primary.btn-outline {
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  background-color: #fff;
  transition: all 0.3s;
}

.btn-primary.btn-outline:hover {
  border: 1px solid #fc2947;
  background-color: #fc2947;
  color: #fff;
}

.btn-icon {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon img {
  width: 24px;
}

.container {
  width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.d-flex {
  display: flex;
}

.form-control textarea {
  display: block;
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 100px;
  padding: 8px;
  width: 100%;
}

.navbar-section {
  background-color: white;
  justify-content: flex-end;
  padding: 19px 40px;
}

.navbar-section .container {
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-section .navbar-logo>img {
  height: 46.245px;
}

.hero-section {
  padding: 60px 0;
  background-image: url("../img/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hero-section .container {
  justify-content: space-between;
  padding: 0px 10%;
  align-items: center;
}

.signin-wrap {
  width: 360px;
  padding: 70px 18px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 8px 0px;
}

.signin-wrap h2 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 40px;
}

.signin-form label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
}

.signin-form select {
  padding: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 0px;
}

.signin-form input {
  border: 1px solid var(--secondary-color-100);
  padding: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
}

.signin-form select+input {
  margin-left: -1px;
}

.signin-form {
  margin-bottom: 30px;
}

.signin-wrap p a {
  color: var(--neutral-color-300);
}

.signin-wrap p {
  margin-bottom: 30px;
}

.welcome-msg-wrap {
  border-radius: 10px;
  opacity: 0.9;
  background: var(--primary-color-100);
  width: 553px;
  color: rgb(255, 255, 255);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.welcome-msg-wrap h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
}

.welcome-msg-wrap p {
  font-size: 28px;
  font-weight: 200;
}

.features-section {
  background-color: var(--secondary-color-400);
  padding: 100px 0px;
}

.features-section .container {
  justify-content: space-between;
  padding: 0px 10%;
  align-items: center;
}

.features-card {
  width: 319px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--primary-color-100);
  color: white;
}

.features-card-img {
  width: 100%;
  padding: 27px 34px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 230px;
  text-align: center;
}

.features-card-content {
  padding: 35px 10px;
  text-align: center;
}

.features-card-content h3 {
  margin-bottom: 15px;
}

.features-card-content p {
  line-height: 25px;
}

.callout-section {
  background-color: var(--primary-color-100);
  color: white;
  display: flex;
  align-items: center;
}

.callout-text {
  width: 50%;
  height: 100%;
  padding: 0 30px;
}

.callout-img {
  width: 50%;
  height: 100%;
}

.callout-img img {
  width: 100%;
  margin-bottom: -5px;
}

.callout-text p {
  font-size: 28px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}

.callout-text p+p {
  margin-top: 20px;
}

.howworks-section {
  margin-top: 30px;
  text-align: center;
}

.howworks-steps {
  background-color: var(--secondary-color-400);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.howworks-section h3 {
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 400;
}

.howworks-steps p {
  font-size: 26px;
  margin-top: 24px;
}

.calltoaction-section {
  padding: 70px 0;
  display: flex;
  justify-content: center;
}

.calltoaction-wrap {
  background-color: var(--primary-color-100);
  padding: 50px 80px;
  width: 820px;
  border-radius: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.calltoaction-wrap p {
  font-size: 32px;
}

.calltoaction-wrap a {
  padding: 10px;
  font-size: 26px;
  color: white;
}

.faq-section h3 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 400;
}

.faq-group {
  padding: 0 10%;
  margin-bottom: 3rem;
}

.faq-title {
  background-color: #fff;
  border: 0;
  font-size: 22px;
  justify-content: space-between;
  width: 100%;
  padding: unset;
  text-align: left;
}

.faq-body {
  font-size: 20px;
  color: var(--neutral-color-200);
}

.faq-wrap+.faq-wrap {
  margin-top: 20px;
}

.faq-wrap {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home-need-nav{
  display: flex;
  align-items: center;
}
.home-help .home-need-nav img{
  height: 17px;
}
.faq-wrap.active {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.24);
}

.mb-60px {
  margin-bottom: 60px;
}

.footer-section {
  background-color: var(--primary-color-200);
  /* margin-top: 60px; */
  color: #fff;
}

.footer-links {
  padding: 15px 10%;
  display: flex;
}

.footer-links a {
  color: #fff;
  display: block;
}

.footer-links a+a {
  margin-top: 10px;
}

.footer-links>div+div {
  margin-left: 60px;
}

.footer-copyright {
  border-top: 1px solid var(--secondary-color-110);
  padding: 15px 9%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-copyright a+a {
  margin-left: 10px;
}

.otp-section {
  position: fixed;
  background-color: rgb(0 0 0 / 60%);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-card {
  width: 570px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px 20px 40px;
}

.otp-card>* {
  margin: 10px 0;
}

.otp-card label {
  text-align: center;
  width: 100%;
  display: block;
}

.otp-input {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.otp-input input {
  border: 1px solid var(--secondary-color-100);
  border-radius: 5px;
  display: flex;
  width: 30px;
  height: 35px;
  margin: 0 5px;
  text-align: center;
}

.otp-input>div {
  position: relative;
}

.otp-input>div::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 19px;
  background-color: var(--secondary-color-100);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 5px;
  border-radius: 100px;
  user-select: none;
}

.otp-resend {
  font-size: 16px;
  font-weight: 600;
  border: 0;
  background-color: transparent;
}

.otp-resend.inactive {
  color: var(--neutral-color-200);
  cursor: default;
}

.otp-resend.resend {
  color: var(--primary-color-100);
}

.otp-card .btn-primary {
  width: 265px;
}

.close-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
}

.close-section p {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: var(--neutral-color-200);
}

.otp-invalid-text {
  color: var(--primary-color-100);
}

.otp-invalid-input input {
  border-color: var(--primary-color-100);
}

/* Web App */
.full-page {
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.main {
  flex-grow: 1;
  width: 100vw;
}

.menu-side {
  background: white;
  flex-direction: column;
  width: 210px;
  height: 100%;
}

.menu-link.active p {
  color: #fff;
}

.menu-link p {
  color: #393e46;
}

.main-body {
  flex-grow: 1;
  padding: 20px 40px;
  overflow-y: auto;
  height: calc(100vh - 89px);
  z-index: 1;
}

.menu-link {
  padding: 16px 10px;
  border-top: 1px solid var(--secondary-color-110);
  border-bottom: 1px solid var(--secondary-color-110);
}

.menu-link {
  border-top: 0;
}

.menu-link img {
  margin-right: 10px;
  color: var(--primary-color-200);
}

.menu-link.active {
  background-color: var(--primary-color-100);
  color: white;
}
.menu-link.active img {
  color: white;
}
.full-page .navbar-section {
  border-bottom: 1px solid var(--secondary-color-110);
  height: 89px;
  z-index: 99;
  position: relative;
}

.navbar-action img {
  height: 20px;
  margin-right: 5px;
}

.navbar-action a+a {
  margin-left: 20px;
}

.form-control>label {
  display: block;
  margin-bottom: 5px;
}

.form-control input,
.form-control select {
  display: block;
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 35px;
  padding: 8px;
}

.form-control>input:focus {
  border: 1px solid var(--primary-color-100);
}

.form-control select {}

.b-d-form-block {
  border-radius: 10px;
  border: 1px solid var(--secondary-color-110);
  padding: 10px;
}

.business-details-form {
  max-width: 560px;
}

.b-d-form-row>.form-control {
  width: 50%;
}

.b-d-form-row>.form-control input {
  width: 100%;
}

.b-d-form-row>.form-control+.form-control {
  margin-left: 10px;
}

.main-page-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
}

.b-d-form-block+.b-d-form-block {
  margin-top: 10px;
}

.f-c-horizontal label {
  white-space: nowrap;
  margin-right: 10px;
}

.b-d-form-row>.form-control.f-c-horizontal {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.form-gst-percentage {
  align-items: center;
  margin-left: 10px;
}

.form-control.f-c-checkbox>label {
  display: flex !important;
  margin: 0;
  align-items: center;
  user-select: none;
}

.form-control.f-c-checkbox>label+label {
  margin-left: 15px;
}

.form-control.f-c-checkbox>label span+input {
  margin-left: 10px;
}

.form-control.f-c-checkbox>label input+span {
  margin-left: 5px;
}

.form-gst-percentage input+p {
  margin-left: 10px;
}

.b-d-form-row+.b-d-form-row {
  margin-top: 20px;
}

.form-control.f-c-phone .f-c-phone-input select {
  border-right: 0;
  padding-right: unset;
  border-radius: 5px 0 0 5px !important;
}

.form-control.select-drop select {
  border-radius: 5px !important;
}

.input-black-txt input {
  color: #393e46 !important;
}

.form-control.f-c-phone .f-c-phone-input select+input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control.f-c-checkbox input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

.b-d-form-action {
  justify-content: center;
  padding: 10px;
  margin-top: 20px;
}

.b-d-form-action .btn-primary {
  width: 200px;
}

.b-d-form-action .btn-primary+.btn-primary {
  margin-left: 10px;
}

.ham-menu-icon {
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid var(--secondary-color-110);
}

.ham-menu-icon.active {
  border: 1px solid var(--primary-color-100);
}

.ham-menu-icon img {
  height: 24px !important;
}

.dashboard {
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-data-card-wrap {
  padding: 20px;
  border: 1px solid var(--secondary-color-110);
  border-radius: 10px;
  width: 800px;
}

.dashboard-data-card-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dashboard-data-card-title h2 {
  font-size: 18px;
  font-weight: 500;
}

.dashboard-data-card-title select {
  height: 24px;
  border-radius: 1000px;
  font-size: 13px;
  padding: 0 10px;
}

.dashboard-data-card-details {
  flex-wrap: wrap;
  justify-content: center;
}

.d-d-card {
  border: 1px solid var(--secondary-color-100);
  padding: 10px;
  border-radius: 10px;
  width: 48%;
  margin: 0 1% 15px;
}

.d-d-card h3 {
  font-weight: 500;
  margin-bottom: 15px;
}

.d-d-card p {
  font-size: 13px;
}

.d-d-c-sales>.d-flex {
  background-color: var(--secondary-color-120);
  padding: 2px 5px;
  border-radius: 1000px;
  justify-content: space-between;
  align-items: center;
}

.d-d-c-sales>.d-flex+.d-flex {
  margin-top: 8px;
}

.d-d-c-order .d-d-c-orders-chart {
  display: flex;
  align-items: center;
}

.d-d-c-orders-name {
  width: 50%;
}

.d-d-c-orders-chart+.d-d-c-orders-chart {
  margin-top: 20px;
}

.d-d-c-orders-chart .chart-linear {
  width: 40%;
  align-items: center;
}

.chart-linear .chart-linear-color {
  background: gray;
  position: relative;
  height: 12px;
  border-radius: 100px;
}

.chart-linear .chart-linear-color p {
  position: absolute;
  right: -32px;
  top: -0.9px;
  width: 29px;
  text-align: left;
}

.d-d-c-orders-name {
  align-items: center;
}

.d-d-c-orders-name img {
  margin-right: 10px;
}

.d-d-c-sellers .chart-linear-color {
  background-color: var(--primary-color-100);
}

.setup-process {
  border: 1px solid var(--secondary-color-110);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
  padding: 20px 10px;
  border-radius: 10px;
  text-align: center;
  min-width: 180px;
  margin-left: 50px;
}

.setup-process>div+div {
  margin-top: 20px;
}

.setup-process-percent h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.setup-process-percent .chart-linear {
  height: 5px;
  background-color: var(--secondary-color-110);
  border-radius: 100px;
}

.setup-process-percent .chart-linear .chart-linear-color {
  height: 5px;
  background-color: var(--success-color-100);
  border-radius: 100px;
}

.setup-process-percent p {
  font-size: 13px;
  margin-top: 5px;
}

.s-p-step-success {
  padding: 10px 10px;
  border-radius: 10px;
  background: #e9ffd8;
  text-align: center;
}

.s-p-step-pending {
  padding: 10px 10px;
  border-radius: 10px;
  background: #ffeef0;
  text-align: center;
}

.setup-process-steps>*+* {
  margin-top: 10px;
}

.setup-process-steps p {
  font-size: 14px;
}

.dashboard-nodata {
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-nodata .features-card {
  margin: 0 10px 0;
}

.dashboard-nodata .features-card {
  width: 46%;
  margin-bottom: 20px;
  max-width: 300px;
}

.food-truck {
  max-width: 1052px;
}

.table-card {
  flex-direction: column;
  border: 1px solid var(--secondary-color-110);
  border-radius: 5px;
}

.table-card+.table-card {
  margin-top: 40px;
}

.table-card-head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.table-card-head h1 {
  margin: 0;
}

.btn-curve {
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1000px;
  align-items: center;
  padding: 5px 10px;
  background-color: var(--secondary-color-400);
  border: 0;
  min-height: 34px;
  transition: all 0.3s;
}

.btn-curve:hover img {
  filter: brightness(10);
}

.btn-curve:hover {
  background-color: #fc2947;
}

.btn-curve:hover p {
  color: #fff;
}

.btn-curve img+p {
  margin-left: 10px;
}

.btn-curve img {
  height: 24px !important;
  width: auto !important;
}

.btn-curve p {
  color: var(--primary-color-200);
  font-size: 16px;
  font-weight: 700;
}

.table-card-row-wrap {
  flex-direction: column;
}

.table-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.table-card-row+.table-card-row {
  border-top: 1px solid var(--secondary-color-110);
}

.table-card-row:nth-child(odd) {
  background: var(--secondary-color-120);
}

.f-t-name {
  align-items: center;
}

.f-t-name img {
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 100px;
}

.f-t-name p {
  margin-left: 10px;
  width: 500px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;
  overflow: visible;
}

.btn-link {
  font-size: 16px;
  font-weight: 700;
  color: var(--neutral-color-200);
  background-color: transparent;
  border: 0;
  white-space: nowrap;
}

.btn-link:hover {
  color: #fc2947;
}

.side-controls {
  border-left: 1px solid var(--secondary-color-100);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.24);
  padding: 40px 20px;
  width: 600px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
}

.side-control-head {
  font-size: 26px;
  font-weight: 500;
  margin: 10px 0 30px;
  margin-right: 5px;
}

.food-truck-logo-uploaded img {
  max-width: 80px;
  border-radius: 5px;
}

.food-truck-logo-uploaded {
  justify-content: space-between;
  align-items: center;
}

.food-truck-logo-uploaded>.d-flex {
  align-items: center;
}

.food-truck-logo-uploaded>.d-flex .btn-icon img {
  width: 20px;
}

.b-d-form-row h4 {
  white-space: nowrap;
  margin-top: 4px;
  margin-right: 10px;
}

.b-d-form-row h4+.form-control.f-c-checkbox {
  width: auto;
}

.table-card-row-new {
  align-items: center;
  padding: 20px 10px;
}

.file-upload-drag {
  height: 300px;
  padding: 20px;
  border: 1px dashed var(--secondary-color-100);
  border-radius: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-upload-drag h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.file-upload-drag h4 span {
  color: var(--primary-color-100);
}

.file-upload-action .btn-primary {
  width: 180px;
}

.file-upload-action .btn-primary+.btn-primary {
  margin-left: 20px;
}

.file-upload-logo {
  width: 100%;
}

.file-upload-logo img {
  /* width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px; */

  width: 100%;
  /* width: 500px; */
  height: 300px;
  /* object-fit: cover; */
  /* object-fit: cover; */
  /* border-radius: 10px; */
  /* width: 600px; */
  /* height: 350px; */
  /* object-fit: contain; */
  /* position: relative; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

.schedule-truck-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  background-color: var(--secondary-color-120);
  padding: 10px 5px;
  display: block;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 10px;
}

/*  */
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: auto;
  box-sizing: border-box;
}

.toggle-button-cover .knobs,
.toggle-button-cover .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.toggle-button-cover .button {
  position: relative;
  width: 94px;
  height: 35px;
  overflow: hidden;
}

.toggle-button-cover .button.r,
.toggle-button-cover .button.r .layer {
  border-radius: 100px;
}

.toggle-button-cover .button.b2 {
  border-radius: 1000px;
}

.toggle-button-cover .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.toggle-button-cover .knobs {
  z-index: 2;
}

.toggle-button-cover .layer {
  width: 100%;
  background-color: var(--secondary-color-110);
  transition: 0.3s ease all;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 1000px;
}

/* Button 13 */
.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover #button-13 .knobs span,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after,
.toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover .button.b2 .knobs:before {
  content: "Open";
  left: 8px;
}

.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover .button.b2 .knobs:after {
  content: "Closed";
  right: 29px;
}

.toggle-button-cover #button-13 .knobs span,
.toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover #button-13 .checkbox:checked+.knobs span,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 64px;
  background-color: #fff;
}

.toggle-button-cover #button-13 .checkbox:checked~.layer,
.toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-13 .knobs span::after,
.toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 35px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover #button-13 .checkbox:checked+.knobs span::after,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-13 .knobs span::before,
.toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* User Role Toggle */
.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .knobs span,
.user-role-add .toggle-button-cover .button.b2 .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:before {
  content: "Enable";
  left: 8px;
}

.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs:after {
  content: "Disable";
  right: 29px;
}

.user-role-add .toggle-button-cover .knobs span,
.user-role-add .toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.user-role-add .toggle-button-cover .checkbox:checked+.knobs span,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 69px;
  background-color: #fff;
}

.user-role-add .toggle-button-cover .checkbox:checked~.layer,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.user-role-add .toggle-button-cover .knobs span::after,
.user-role-add .toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 42px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.user-role-add .toggle-button-cover .checkbox:checked+.knobs span::after,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.user-role-add .toggle-button-cover .knobs span::before,
.user-role-add .toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* User Role Toggle */

/* Common Toggle */
.toggle-button-cover .knobs:before,
.toggle-button-cover .knobs:after,
.toggle-button-cover .knobs span,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after,
.toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover .knobs:before,
.toggle-button-cover .knobs:after,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover .knobs:before,
.toggle-button-cover .button.b2 .knobs:before {
  content: "Open";
  left: 8px;
}

.toggle-button-cover .knobs:after,
.toggle-button-cover .button.b2 .knobs:after {
  content: "Closed";
  right: 29px;
}

.toggle-button-cover .knobs span,
.toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover .checkbox:checked+.knobs span,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 64px;
  background-color: #fff;
}

.toggle-button-cover .checkbox:checked~.layer,
.toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover .knobs span::after,
.toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 35px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover .checkbox:checked+.knobs span::after,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.toggle-button-cover .knobs span::before,
.toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* Common Toggle */

.user-role-add .toggle-button-cover .button {
  position: relative;
  width: 100px;
  height: 35px;
  overflow: hidden;
}

.toggle-button-cover #button-14 .knobs:before,
.toggle-button-cover #button-14 .knobs:after,
.toggle-button-cover #button-14 .knobs span {
  position: absolute;
  top: 3px;
  width: 25px;
  height: 18px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 4px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover #button-14 .knobs:before,
.toggle-button-cover #button-14 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover #button-14 .knobs:before {
  content: "Enable";
  left: 4px;
  font-size: 12px;
}

.toggle-button-cover #button-14 .knobs:after {
  content: "Disable";
  right: 15px;
  font-size: 12px;
}

.toggle-button-cover #button-14 .knobs span {
  width: 20px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover #button-14 .checkbox:checked+.knobs span {
  left: 56px;
  background-color: #fff;
}

.toggle-button-cover #button-14 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-14 .knobs span::after {
  content: "";
  position: absolute;
  height: 21px;
  width: 42px;
  left: -7px;
  top: -4px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover #button-14 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 55px;
  left: -15px;
  top: -3px;
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-14 .knobs span::before {
  height: 19px;
  width: 18px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 2px;
  border-radius: 1000px;
  z-index: 9;
}

.min-h-280px {
  min-height: 280px;
}

.side-controls.item-justify-start {
  justify-content: flex-start;
}

.side-controls.item-justify-start .side-control-head {
  margin: 30px 0;
}

.b-d-form-block-schedule select {
  border-radius: 1000px;
  width: 108px;
}

.b-d-form-block-schedule .form-control {
  width: auto;
}

.b-d-form-block-schedule .b-d-form-row .form-control:first-child {
  width: 200px;
}

.b-d-form-block-schedule .toggle-button-cover {
  margin-left: 10px;
}

.b-d-form-block.b-d-form-block-schedule {
  padding: 0;
  border: 0;
}

/*  */
.table-card-row-new {
  align-items: center;
  padding: 20px 10px;
}

.file-upload-drag {
  height: 300px;
  padding: 20px;
  border: 1px dashed var(--secondary-color-100);
  border-radius: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.file-upload-drag h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.file-upload-drag h4 span {
  color: var(--primary-color-100);
}

.file-upload-action .btn-primary {
  width: 180px;
}

.file-upload-action .btn-primary+.btn-primary {
  margin-left: 20px;
}

.file-upload-logo {
  width: 100%;
}

.file-upload-logo img {
  /* width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px; */

  width: 100%;
  /* width: 500px; */
  height: 300px;
  /* object-fit: cover; */
  /* object-fit: cover; */
  /* border-radius: 10px; */
  /* width: 600px; */
  /* height: 350px; */
  /* object-fit: contain; */
  /* position: relative; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

.schedule-truck-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  background-color: var(--secondary-color-120);
  padding: 10px 5px;
  display: block;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 10px;
}

/*  */
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: auto;
  box-sizing: border-box;
}

.toggle-button-cover .knobs,
.toggle-button-cover .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.toggle-button-cover .button {
  position: relative;
  width: 94px;
  height: 35px;
  overflow: hidden;
}

.toggle-button-cover .button.r,
.toggle-button-cover .button.r .layer {
  border-radius: 100px;
}

.toggle-button-cover .button.b2 {
  border-radius: 1000px;
}

.toggle-button-cover .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.toggle-button-cover .knobs {
  z-index: 2;
}

.toggle-button-cover .layer {
  width: 100%;
  background-color: var(--secondary-color-110);
  transition: 0.3s ease all;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 1000px;
}

/* Button 13 */
.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover #button-13 .knobs span,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after,
.toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover #button-13 .knobs:before,
.toggle-button-cover .button.b2 .knobs:before {
  content: "Open";
  left: 8px;
}

.toggle-button-cover #button-13 .knobs:after,
.toggle-button-cover .button.b2 .knobs:after {
  content: "Closed";
  right: 29px;
}

.toggle-button-cover #button-13 .knobs span,
.toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover #button-13 .checkbox:checked+.knobs span,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 64px;
  background-color: #fff;
}

.toggle-button-cover #button-13 .checkbox:checked~.layer,
.toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-13 .knobs span::after,
.toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 35px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover #button-13 .checkbox:checked+.knobs span::after,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-13 .knobs span::before,
.toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* User Role Toggle */
.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .knobs span,
.user-role-add .toggle-button-cover .button.b2 .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.user-role-add .toggle-button-cover .knobs:before,
.user-role-add .toggle-button-cover .button.b2 .knobs:before {
  content: "Enable";
  left: 8px;
}

.user-role-add .toggle-button-cover .knobs:after,
.user-role-add .toggle-button-cover .button.b2 .knobs:after {
  content: "Disable";
  right: 29px;
}

.user-role-add .toggle-button-cover .knobs span,
.user-role-add .toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.user-role-add .toggle-button-cover .checkbox:checked+.knobs span,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 69px;
  background-color: #fff;
}

.user-role-add .toggle-button-cover .checkbox:checked~.layer,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.user-role-add .toggle-button-cover .knobs span::after,
.user-role-add .toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 42px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.user-role-add .toggle-button-cover .checkbox:checked+.knobs span::after,
.user-role-add .toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.user-role-add .toggle-button-cover .knobs span::before,
.user-role-add .toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* User Role Toggle */

/* Common Toggle */
.toggle-button-cover .knobs:before,
.toggle-button-cover .knobs:after,
.toggle-button-cover .knobs span,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after,
.toggle-button-cover .button.b2 .knobs span {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover .knobs:before,
.toggle-button-cover .knobs:after,
.toggle-button-cover .button.b2 .knobs:before,
.toggle-button-cover .button.b2 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover .knobs:before,
.toggle-button-cover .button.b2 .knobs:before {
  content: "Open";
  left: 8px;
}

.toggle-button-cover .knobs:after,
.toggle-button-cover .button.b2 .knobs:after {
  content: "Closed";
  right: 29px;
}

.toggle-button-cover .knobs span,
.toggle-button-cover .button.b2 .knobs span {
  width: 25px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover .checkbox:checked+.knobs span,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span {
  left: 64px;
  background-color: #fff;
}

.toggle-button-cover .checkbox:checked~.layer,
.toggle-button-cover .button.b2 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover .knobs span::after,
.toggle-button-cover .button.b2 .knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 35px;
  left: 0px;
  top: 6px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover .checkbox:checked+.knobs span::after,
.toggle-button-cover .button.b2 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 13px;
  width: 31px;
  left: -19px;
  top: 6px;
  background-color: var(--success-color-100);
}

.toggle-button-cover .knobs span::before,
.toggle-button-cover .button.b2 .knobs span::before {
  height: 25px;
  width: 25px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 1000px;
  z-index: 9;
}

/* Common Toggle */

.user-role-add .toggle-button-cover .button {
  position: relative;
  width: 100px;
  height: 35px;
  overflow: hidden;
}

.toggle-button-cover #button-14 .knobs:before,
.toggle-button-cover #button-14 .knobs:after,
.toggle-button-cover #button-14 .knobs span {
  position: absolute;
  top: 3px;
  width: 25px;
  height: 18px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 4px 0px;
  border-radius: 1000px;
  transition: 0.3s ease all;
}

.toggle-button-cover #button-14 .knobs:before,
.toggle-button-cover #button-14 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.toggle-button-cover #button-14 .knobs:before {
  content: "Enable";
  left: 4px;
  font-size: 12px;
}

.toggle-button-cover #button-14 .knobs:after {
  content: "Disable";
  right: 15px;
  font-size: 12px;
}

.toggle-button-cover #button-14 .knobs span {
  width: 20px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
}

.toggle-button-cover #button-14 .checkbox:checked+.knobs span {
  left: 56px;
  background-color: #fff;
}

.toggle-button-cover #button-14 .checkbox:checked~.layer {
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-14 .knobs span::after {
  content: "";
  position: absolute;
  height: 21px;
  width: 42px;
  left: -7px;
  top: -4px;
  background-color: var(--secondary-color-110);
}

.toggle-button-cover #button-14 .checkbox:checked+.knobs span::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 55px;
  left: -15px;
  top: -3px;
  background-color: var(--success-color-100);
}

.toggle-button-cover #button-14 .knobs span::before {
  height: 19px;
  width: 18px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
  left: 2px;
  border-radius: 1000px;
  z-index: 9;
}

.min-h-280px {
  min-height: 280px;
}

.side-controls.item-justify-start {
  justify-content: flex-start;
}

.side-controls.item-justify-start .side-control-head {
  margin: 30px 0;
}

.b-d-form-block-schedule select {
  border-radius: 1000px;
  width: 108px;
}

.b-d-form-block-schedule .form-control {
  width: auto;
}

.b-d-form-block-schedule .b-d-form-row .form-control:first-child {
  width: 200px;
}

.b-d-form-block-schedule .toggle-button-cover {
  margin-left: 10px;
}

.b-d-form-block.b-d-form-block-schedule {
  padding: 0;
  border: 0;
}

/*  */
.table-card-row-new .btn-curve+.btn-curve {
  margin-left: 30px;
}

.data-table-group {
  padding: 10px;
}

.data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.data-table-wrap+.data-table-wrap {
  margin-top: 30px;
}

.data-table-head {
  padding: 10px;
  justify-content: space-between;
}

.data-table-head h4 {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--primary-color-100);
  color: #fff;
  font-weight: 500;
}

.d-t-head-left,
.d-t-head-right {
  align-items: center;
}

.d-t-head-left>*+*,
.d-t-head-right>*+* {
  margin-left: 20px;
}

.data-table-body table {
  width: 100%;
  border-collapse: collapse;
}

.data-table-body td {
  padding: 10px;
}

.data-table-body thead tr {
  background-color: var(--secondary-color-400);
}

.data-table-body thead tr td {
  font-weight: 500;
  border-color: var(--secondary-color-400);
}

.data-table-body tbody tr td {
  font-size: 14px;
  padding: 15px 10px;
  vertical-align: text-top;
}

.data-table-body tbody tr td:first-child {
  font-size: 16px;
  font-weight: 500;
}

.data-table-body tbody tr:nth-child(even) {
  background-color: var(--secondary-color-120);
}

.r-t-s-r-s-item .data-table-body button {
  border: unset;
  background: unset;
}

.main-page-title button {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: -35px;
  top: 2px;
}

.main-page-title button img {
  height: 20px;
}

.table-card-head .btn-primary.btn-outline {
  width: 180px;
}

.table-card-body {
  border-top: 1px solid var(--secondary-color-100);
}

.table-card-head h1 span {
  display: block;
  color: var(--secondary-color-100);
  font-size: 16px;
  font-weight: 500;
}

.table-card-row .btn-icon img {
  width: 24px;
  min-width: 24px;
}

.t-c-r-title-col {
  align-items: center;
}

.t-c-r-title-col p {
  color: var(--primary-color-200);
}

.master-guide-image-popup .otp-card {
  min-width: 1000px;
}

.side-control-head+p {
  margin-bottom: 20px;
}

.b-d-form-block .f-c-label-horzontal>label {
  white-space: nowrap;
  margin-right: 10px;
}

/* Dev styles */

.btn-full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.error-msg-div {
  color: var(--primary-color-100);
  font-size: small;
  padding: 2px 3px;
}

.error-msg-div-schedule {
  color: var(--primary-color-100);
  font-size: 10px;
  padding: 2px 3px;
  text-align: center;
}

.input-error {
  border: 1px solid var(--primary-color-100) !important;
}

.navbar-action div+div {
  margin-left: 20px;
}

.navbar-action {
  cursor: pointer;
}

.logo-wrapper-dev .img1 {
  margin: 0 10px;
  height: 34.245px;
}

.logo-wrapper-dev .img2 {
  height: 29.245px;
}

/* Dev styles */

.menu-saved-items-list {
  overflow-y: auto;
  height: 100%;
}

.menu-saved-item {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.menu-saved-item h5 {
  font-weight: 400;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-saved-item-price {
  min-width: 77px;
}

.menu-saved-item div p {
  color: var(--neutral-color-200);
}

.menu-saved-item>*+* {
  margin-left: 20px;
}

.menu-saved-item .btn-link {
  white-space: nowrap;
}

.menu-saved-item:nth-child(odd) {
  background-color: var(--secondary-color-120);
}

.selection-chips-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.selection-chip {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color-100);
  border-radius: 100px;
  padding: 5px 2px 5px 10px;
}

.selection-chip img {
  width: 20px;
}

.b-d-form-row .table-card-row-wrap {
  width: 100%;
  border: 1px solid var(--secondary-color-110);
  padding: 10px;
  border-radius: 10px;
}

.b-d-form-row .table-card-row-wrap .table-card-row:nth-child(odd) {
  background: #fff;
}

.b-d-form-block .f-c-label-horzontal {
  align-items: center;
  font-size: 16px;
}

.b-d-form-block .f-c-label-horzontal>input {
  color: #a9a8a8;
  font-size: 13px;
  padding: 8px;
  width: 265px;
  height: 35px;
}

.b-d-form-row .b-d-form-row-head {
  margin-bottom: 20px;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox {
  align-items: center;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox p {
  margin-right: 10px;
}

.b-d-form-row .b-d-form-block .b-d-f-checkbox-2 {
  margin-right: 20px !important;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row {
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-top: 10px;
  border-top: none;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row {
  gap: 15px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row .t-c-r-title-col input {
  width: 100%;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col>input {
  border-color: #a9a8a8;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-r-input-2 {
  border-color: #a9a8a8;
  border-radius: 4px;
  width: 52px;
  height: 35px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 7px 8px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-t-optiondetail-doller {
  margin-right: 2px;
  font-size: 16px;
}

.b-d-form-optiondetail-section {
  margin-top: 20px;
}

.b-d-form-block .b-d-form-block-min-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.b-d-form-block .b-d-form-block-min-option:last-child {
  margin-bottom: unset;
}

.b-d-form-block .b-d-form-block-max-option {
  align-items: center;
  justify-content: space-between;
}

.b-d-form-block .b-d-form-block .b-d-form-block-min-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
}

.b-d-form-block .b-d-form-block .b-d-form-block-max-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
}

.side-control-head-title-section .img-fluid {
  width: 25px;
  height: 25px;
}

.side-control-head-title-section {
  align-items: center;
}

.side-control-head-title-section .img-fluid {
  font-size: 26px;
  font-weight: 500;
  margin: 50px 0 30px;
}

.side-control-head-title-section {
  margin: 50px 0 10px;
}

.side-control-head-title-section.menu-preview-side-control {
  justify-content: space-between;
}

.preview-menu .side-control-head-title-section img {
  width: 100%;
}

.table-card-row .t-c-r-title-col-addbtn {
  width: 80px;
  height: 35px;
  border-radius: 30px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}

.table-card-row .t-c-r-title-col .t-c-r-labeltext {
  font-size: 13px;
  color: #393e46;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.r-t-g-sales-table-items {
  border-top: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  border-bottom: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.r-t-g-s-t-i-overview {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.r-t-g-s-t-i-summary {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.divider {
  border-radius: 20px;
  background: var(--Secondary-Colors-Secondary-Color-110, #d9d9d9);
  width: 2px;
  align-self: stretch;
}

.horizondal-divider {
  background: #a9a8a8;
  height: 2px;
  align-self: stretch;
}

.report-table-group {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.r-t-g-s-t-i-title {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  color: var(--Primary-Primary-Color-100, #fc2947);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.r-t-g-s-t-i-t-i-c-info-cards {
  border-radius: 20px;
  border: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  background: var(--Secondary-Colors-Secondary-Color-200, #fff);
  display: flex;
  max-width: 180px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  width: 100%;
  flex: 1 1 48%;
  /* Two cards per row with some margin */
}

.r-t-g-s-t-i-t-i-c-title {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Secondary-Colors-Secondary-Color-120, #f7f7f7);
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 16px 3px 11px;
}

.report-table-sub-title {
  background: var(--Background-Color-Beige, #f9f5eb);
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}

.r-t-s-r-i-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truck-cards-detail-section {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 100px;
  align-self: stretch;
}

.report-table-sales-report-section {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

/*calender*/

.datepicker-top {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.tag {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 0;
  background-color: var(--c-bg-secondary);
  border-radius: 10px;
  padding: 0.5em 0.75em;
  font-weight: 600;
}

.month-selector {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.month-name {
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  height: 24px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.datepicker {
  border-radius: 10px;
  border: 1px solid var(--secondary-color-110);
  padding: 5px;
}

.datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 1rem;
}

.day,
.date {
  justify-self: center;
}

.day {
  color: #393e46;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.main-calender {
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.weekend-day {
  color: #f33 !important;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #291a1a;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
    color: #ff6000;
    border: 2px solid #ff6000;
  }
}

.faded {
  color: #a9a8a8;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.current-day {
  color: #fff;
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: #fff;
  }
}

.report-calender-title {
  color: #393e46;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}

.from-to-title {
  margin: 5px 0px 5px 0px;
  font-size: 13px;
  color: #393e46;
}

button.r-t-f-d-i-i-day {
  display: flex;
  padding: 4px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-Colors-Secondary-Color-100, #a9a8a8);
  background-color: #ffff;
  white-space: nowrap;
  font-size: 16px;
}

.r-t-f-d-i-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.r-f-d-i-i-date>input {
  border-radius: 20px;
  border: 1px solid #a9a8a8;
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.r-f-d-i-i-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-bottom: 10px;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.r-t-f-d-i-title {
  margin: 10px 0px;
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 52px 2px 11px;
}

.r-t-s-r-i-title .form-control {
  display: flex;
  align-items: center;
  gap: 25px;
}

.r-t-s-r-i-title h5 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.report-page h4 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

button.r-t-f-d-i-i-day:hover {
  background-color: #fc2947;
  color: #fff;
  border-color: #fc2947;
}

.r-t-f-d-i-item .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.sales-summary-report-date-title {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 20px;
}

.sales-summary-repor-titles {
  display: flex;
}

.sales-summary-repor-titles {
  gap: 10px;
  align-items: center;
}

p.r-t-f-d-i-i-t-s-t-title-1 {
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

p.r-t-f-d-i-i-t-s-t-title-2 {
  color: #393e46;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.r-t-f-d-i-i-table-sub-title {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.sales-summary-report-titles {
  display: flex;
  gap: 10px;
  align-items: center;
}

.r-t-f-d-i-i-t-s-t-short-date {
  color: #878787;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-record-total-count {
  display: flex;
  gap: 10px;
}

.sales-record-total-count p {
  border-radius: 5px;
  background: #f9f5eb;
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.report-page .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.r-t-s-r-s-item .data-table-body tbody tr td:first-child {
  font-size: 13px;
  display: flex;
  width: 260px;
  padding: 10px 10px;
  align-items: center;
  font-weight: normal;
}

.r-t-s-r-s-item .data-table-body thead tr td {
  font-weight: 500;
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
}

.r-t-s-r-s-item .data-table-body tbody tr td {
  font-size: 13px;
  padding: 15px 10px;
}

.report-page .data-table-body tbody tr:nth-child(even) {
  background-color: unset !important;
}

.report-page .view-more {
  color: #878787;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}

.r-t-s-r-s-item {
  display: flex;
  flex-direction: column;
}

.report-date-dropdowen .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 91px 2px 11px;
}

.click-button {
  background-color: #fc2947 !important;
  color: #fff !important;
  border-color: #fc2947 !important;
}

/* 111 */
/* Dev styles */

.disabled-button {
  opacity: 0.5;
}

.alignment-left-4 {
  width: 100%;
  margin-left: 4rem;
}

.mt-1 {
  margin-top: 5px;
}

.currency-symbol-wrapper-text {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text-align-left {
  text-align: left !important;
}

.qr-instruction-img {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.image-sections {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 210px;
  height: 100%;
}

.image-sections img {
  width: 50px;
  margin-bottom: 1rem;
}

.image-sections p {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.customization-notes {
  color: #878787;
  font-size: 14px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.option-detail-border-unset {
  border: unset !important;
  padding: unset !important;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.qr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-control-adornment input {
  border: transparent;
  border-radius: 5px;
  padding: 7px;
}

.form-control-adornment button {
  background: transparent;
  border: transparent;
}

.social-media-card-row .form-control {
  align-items: center;
  margin-right: 20px;
}

.social-media-card-row .form-control-adornment .s-f-label {
  align-items: center;
  justify-content: space-between;
}

.social-media-card-row .form-control .s-f-label {
  align-items: center;
}

.social-media-card-row .form-control-adornment .b-d-form-action {
  margin: 0px 0px 0px 81.5px;
}

.social-media-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}

.social-media-card-row .form-control>label img {
  margin-right: 20px;
}

.social-media-card-row .form-control .s-f-label>label {
  margin-right: 20px;
}

.social-media-card-row .b-d-form-action a,
.social-media-card-row .b-d-form-action button {
  margin-left: 37px;
}

.form-control-adornment .input,
.form-control-adornment select {
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 35px;
  width: 100%;
  display: flex;
}

.mr-2 {
  margin: 0px 0 0 19px;
}

.form-control>input:focus {
  border: 1px solid var(--primary-color-100);
}

.btn-full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.error-txt {
  color: var(--primary-color-100);
}

.bottom-error-txt {
  color: var(--primary-color-100);
  padding: 10px;
}

.error-msg-div {
  color: var(--primary-color-100);
  font-size: small;
  padding: 2px 3px;
}

.input-error {
  border: 1px solid var(--primary-color-100) !important;
}

.navbar-action div+div {
  margin-left: 20px;
}

.navbar-action {
  cursor: pointer;
}

.logo-wrapper-dev .img1 {
  margin: 0 10px;
  height: 34.245px;
}

.logo-wrapper-dev .img2 {
  height: 29.245px;
}

/* Dev styles */

.menu-saved-items-list {
  overflow-y: auto;
  height: 100%;
}

.menu-saved-item {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.menu-saved-item h5 {
  font-weight: 400;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-saved-item-price {
  min-width: 77px;
}

.menu-saved-item div p {
  color: var(--neutral-color-200);
}

.menu-saved-item>*+* {
  margin-left: 20px;
}

.menu-saved-item .btn-link {
  white-space: nowrap;
}

.menu-saved-item:nth-child(odd) {
  background-color: var(--secondary-color-120);
}

.selection-chips-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.selection-chip {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color-100);
  border-radius: 100px;
  padding: 5px 2px 5px 10px;
}

.selection-chip img {
  width: 20px;
}

.b-d-form-row .table-card-row-wrap {
  width: 100%;
  border: 1px solid var(--secondary-color-110);
  padding: 10px;
  border-radius: 10px;
}

.b-d-form-row .table-card-row-wrap .table-card-row:nth-child(odd) {
  background: #fff;
}

.b-d-form-block .f-c-label-horzontal {
  align-items: center;
  font-size: 16px;
}

.b-d-form-block .f-c-label-horzontal>input {
  color: #a9a8a8;
  font-size: 13px;
  padding: 8px;
  width: 265px;
  height: 35px;
}

.b-d-form-row .b-d-form-row-head {
  margin-bottom: 20px;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox {
  align-items: center;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox p {
  margin-right: 10px;
}

.b-d-form-row .b-d-form-block .b-d-f-checkbox-2 {
  margin-right: 20px !important;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row {
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-top: 10px;
  border-top: none;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row {
  gap: 15px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row .t-c-r-title-col input {
  width: 100%;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col>input {
  border-color: #a9a8a8;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-r-input-2 {
  border-color: #a9a8a8;
  border-radius: 4px;
  width: 52px;
  height: 35px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 7px 8px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-t-optiondetail-doller {
  margin-right: 2px;
  font-size: 16px;
}

.b-d-form-optiondetail-section {
  margin-top: 20px;
}

.b-d-form-block .b-d-form-block-min-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.b-d-form-block .b-d-form-block-min-option:last-child {
  margin-bottom: unset;
}

.b-d-form-block .b-d-form-block-max-option {
  align-items: center;
  justify-content: space-between;
}

.b-d-form-block .b-d-form-block .b-d-form-block-min-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
}

.b-d-form-block .b-d-form-block .b-d-form-block-max-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
}

.side-control-head-title-section .img-fluid {
  width: 25px;
  height: 25px;
}

.side-control-head-title-section {
  align-items: center;
}

.side-control-head-title-section .img-fluid {
  font-size: 26px;
  font-weight: 500;
  margin: 50px 0 30px;
}

.side-control-head-title-section {
  margin: 50px 0 10px;
}

.side-control-head-title-section.menu-preview-side-control {
  justify-content: space-between;
}

.preview-menu .side-control-head-title-section img {
  width: 100%;
}

.table-card-row .t-c-r-title-col-addbtn {
  width: 80px;
  height: 35px;
  border-radius: 30px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}

.table-card-row .t-c-r-title-col .t-c-r-labeltext {
  font-size: 13px;
  color: #393e46;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.r-t-g-sales-table-items {
  border-top: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  border-bottom: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.r-t-g-s-t-i-overview {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.r-t-g-s-t-i-summary {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.divider {
  border-radius: 20px;
  background: var(--Secondary-Colors-Secondary-Color-110, #d9d9d9);
  width: 2px;
  align-self: stretch;
}

.horizondal-divider {
  background: #a9a8a8;
  height: 2px;
  align-self: stretch;
}

.report-table-group {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.r-t-g-s-t-i-title {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  color: var(--Primary-Primary-Color-100, #fc2947);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.r-t-g-s-t-i-t-i-c-info-cards {
  border-radius: 20px;
  border: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  background: var(--Secondary-Colors-Secondary-Color-200, #fff);
  display: flex;
  max-width: 180px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  width: 100%;
  flex: 1 1 48%;
  /* Two cards per row with some margin */
}

.r-t-g-s-t-i-t-i-c-title {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Secondary-Colors-Secondary-Color-120, #f7f7f7);
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 16px 3px 11px;
}

.report-table-sub-title {
  background: var(--Background-Color-Beige, #f9f5eb);
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}

.r-t-s-r-i-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truck-cards-detail-section {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 100px;
  align-self: stretch;
}

.report-table-sales-report-section {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

/*calender*/

.datepicker-top {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.tag {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 0;
  background-color: var(--c-bg-secondary);
  border-radius: 10px;
  padding: 0.5em 0.75em;
  font-weight: 600;
}

.month-selector {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.month-name {
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  height: 24px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.datepicker {
  border-radius: 10px;
  border: 1px solid var(--secondary-color-110);
  padding: 5px;
}

.datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 1rem;
}

.day,
.date {
  justify-self: center;
}

.day {
  color: #393e46;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.main-calender {
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.weekend-day {
  color: #f33 !important;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #291a1a;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
    color: #ff6000;
    border: 2px solid #ff6000;
  }
}

.faded {
  color: #a9a8a8;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.current-day {
  color: #fff;
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: #fff;
  }
}

.report-calender-title {
  color: #393e46;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}

.from-to-title {
  margin: 5px 0px 5px 0px;
  font-size: 13px;
  color: #393e46;
}

button.r-t-f-d-i-i-day {
  display: flex;
  padding: 4px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-Colors-Secondary-Color-100, #a9a8a8);
  background-color: #ffff;
  white-space: nowrap;
  font-size: 16px;
}

.r-t-f-d-i-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.r-f-d-i-i-date>input {
  border-radius: 20px;
  border: 1px solid #a9a8a8;
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.r-f-d-i-i-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-bottom: 10px;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.r-t-f-d-i-title {
  margin: 10px 0px;
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 52px 2px 11px;
}

.r-t-s-r-i-title .form-control {
  display: flex;
  align-items: center;
  gap: 25px;
}

.r-t-s-r-i-title h5 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.report-page h4 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

button.r-t-f-d-i-i-day:hover {
  background-color: #fc2947;
  color: #fff;
  border-color: #fc2947;
}

.r-t-f-d-i-item .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.sales-summary-report-date-title {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 20px;
}

.sales-summary-repor-titles {
  display: flex;
}

.sales-summary-repor-titles {
  gap: 10px;
  align-items: center;
}

p.r-t-f-d-i-i-t-s-t-title-1 {
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

p.r-t-f-d-i-i-t-s-t-title-2 {
  color: #393e46;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.r-t-f-d-i-i-table-sub-title {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.sales-summary-report-titles {
  display: flex;
  gap: 10px;
  align-items: center;
}

.r-t-f-d-i-i-t-s-t-short-date {
  color: #878787;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-record-total-count {
  display: flex;
  gap: 10px;
}

.sales-record-total-count p {
  border-radius: 5px;
  background: #f9f5eb;
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.report-page .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.r-t-s-r-s-item .data-table-body tbody tr td:first-child {
  font-size: 13px;
  display: flex;
  width: 260px;
  padding: 10px 10px;
  align-items: center;
  font-weight: normal;
}

.r-t-s-r-s-item .data-table-body thead tr td {
  font-weight: 500;
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
}

.r-t-s-r-s-item .data-table-body tbody tr td {
  font-size: 13px;
  padding: 15px 10px;
}

.report-page .data-table-body tbody tr:nth-child(even) {
  background-color: unset !important;
}

.report-page .view-more {
  color: #878787;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}

.r-t-s-r-s-item {
  display: flex;
  flex-direction: column;
}

.report-date-dropdowen .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 91px 2px 11px;
}

.click-button {
  background-color: #fc2947 !important;
  color: #fff !important;
  border-color: #fc2947 !important;
}

/* 111 */
/* Dev styles */

.disabled-button {
  opacity: 0.5;
}

.alignment-left-4 {
  width: 100%;
  margin-left: 4rem;
}

.mt-1 {
  margin-top: 5px;
}

.currency-symbol-wrapper-text {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text-align-left {
  text-align: left !important;
}

.qr-instruction-img {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.image-sections {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 210px;
  height: 100%;
}

.image-sections img {
  width: 50px;
  margin-bottom: 1rem;
}

.image-sections p {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.customization-notes {
  color: #878787;
  font-size: 14px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.option-detail-border-unset {
  border: unset !important;
  padding: unset !important;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.qr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-control-adornment input {
  border: transparent;
  border-radius: 5px;
  padding: 7px;
}

.form-control-adornment button {
  background: transparent;
  border: transparent;
}

.social-media-card-row .form-control {
  align-items: center;
  margin-right: 20px;
}

.social-media-card-row .form-control-adornment .s-f-label {
  align-items: center;
  justify-content: space-between;
}

.social-media-card-row .form-control .s-f-label {
  align-items: center;
}

.social-media-card-row .form-control-adornment .b-d-form-action {
  margin: 0px 0px 0px 81.5px;
}

.social-media-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}

.social-media-card-row .form-control>label img {
  margin-right: 20px;
}

.social-media-card-row .form-control .s-f-label>label {
  margin-right: 20px;
}

.social-media-card-row .b-d-form-action a,
.social-media-card-row .b-d-form-action button {
  margin-left: 37px;
}

.form-control-adornment .input,
.form-control-adornment select {
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 35px;
  width: 100%;
  display: flex;
}

.mr-2 {
  margin: 0px 0 0 19px;
}

.form-control>input:focus {
  border: 1px solid var(--primary-color-100);
}

.btn-full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.error-txt {
  color: var(--primary-color-100);
}

.bottom-error-txt {
  color: var(--primary-color-100);
  padding: 10px;
}

.error-msg-div {
  color: var(--primary-color-100);
  font-size: small;
  padding: 2px 3px;
}

.input-error {
  border: 1px solid var(--primary-color-100) !important;
}

.navbar-action div+div {
  margin-left: 20px;
}

.navbar-action {
  cursor: pointer;
}

.logo-wrapper-dev .img1 {
  margin: 0 10px;
  height: 34.245px;
}

.logo-wrapper-dev .img2 {
  height: 29.245px;
}

/* Dev styles */

.menu-saved-items-list {
  overflow-y: auto;
  height: 100%;
}

.menu-saved-item {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.menu-saved-item h5 {
  font-weight: 400;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-saved-item-price {
  min-width: 77px;
}

.menu-saved-item div p {
  color: var(--neutral-color-200);
}

.menu-saved-item>*+* {
  margin-left: 20px;
}

.menu-saved-item .btn-link {
  white-space: nowrap;
}

.menu-saved-item:nth-child(odd) {
  background-color: var(--secondary-color-120);
}

.selection-chips-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.selection-chip {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color-100);
  border-radius: 100px;
  padding: 5px 2px 5px 10px;
}

.selection-chip img {
  width: 20px;
}

.b-d-form-row .table-card-row-wrap {
  width: 100%;
  border: 1px solid var(--secondary-color-110);
  padding: 10px;
  border-radius: 10px;
}

.b-d-form-row .table-card-row-wrap .table-card-row:nth-child(odd) {
  background: #fff;
}

.b-d-form-block .f-c-label-horzontal {
  align-items: center;
  font-size: 16px;
}

.b-d-form-block .f-c-label-horzontal>input {
  color: #a9a8a8;
  font-size: 13px;
  padding: 8px;
  width: 265px;
  height: 35px;
}

.b-d-form-row .b-d-form-row-head {
  margin-bottom: 20px;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox {
  align-items: center;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox p {
  margin-right: 10px;
}

.b-d-form-row .b-d-form-block .b-d-f-checkbox-2 {
  margin-right: 20px !important;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row {
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-top: 10px;
  border-top: none;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row {
  gap: 15px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row .t-c-r-title-col input {
  width: 100%;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col>input {
  border-color: #a9a8a8;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-r-input-2 {
  border-color: #a9a8a8;
  border-radius: 4px;
  width: 52px;
  height: 35px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 7px 8px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-t-optiondetail-doller {
  margin-right: 2px;
  font-size: 16px;
}

.b-d-form-optiondetail-section {
  margin-top: 20px;
}

.b-d-form-block .b-d-form-block-min-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.b-d-form-block .b-d-form-block-min-option:last-child {
  margin-bottom: unset;
}

.b-d-form-block .b-d-form-block-max-option {
  align-items: center;
  justify-content: space-between;
}

.b-d-form-block .b-d-form-block .b-d-form-block-min-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
}

.b-d-form-block .b-d-form-block .b-d-form-block-max-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
}

.side-control-head-title-section .img-fluid {
  width: 25px;
  height: 25px;
}

.side-control-head-title-section {
  align-items: center;
}

.side-control-head-title-section .img-fluid {
  font-size: 26px;
  font-weight: 500;
  margin: 50px 0 30px;
}

.side-control-head-title-section {
  margin: 50px 0 10px;
}

.side-control-head-title-section.menu-preview-side-control {
  justify-content: space-between;
}

.preview-menu .side-control-head-title-section img {
  width: 100%;
}

.table-card-row .t-c-r-title-col-addbtn {
  width: 80px;
  height: 35px;
  border-radius: 30px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}

.table-card-row .t-c-r-title-col .t-c-r-labeltext {
  font-size: 13px;
  color: #393e46;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.r-t-g-sales-table-items {
  border-top: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  border-bottom: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.r-t-g-s-t-i-overview {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.r-t-g-s-t-i-summary {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.divider {
  border-radius: 20px;
  background: var(--Secondary-Colors-Secondary-Color-110, #d9d9d9);
  width: 2px;
  align-self: stretch;
}

.horizondal-divider {
  background: #a9a8a8;
  height: 2px;
  align-self: stretch;
}

.report-table-group {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.r-t-g-s-t-i-title {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  color: var(--Primary-Primary-Color-100, #fc2947);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.r-t-g-s-t-i-t-i-c-info-cards {
  border-radius: 20px;
  border: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  background: var(--Secondary-Colors-Secondary-Color-200, #fff);
  display: flex;
  max-width: 180px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  width: 100%;
  flex: 1 1 48%;
  /* Two cards per row with some margin */
}

.r-t-g-s-t-i-t-i-c-title {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Secondary-Colors-Secondary-Color-120, #f7f7f7);
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 16px 3px 11px;
}

.report-table-sub-title {
  background: var(--Background-Color-Beige, #f9f5eb);
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}

.r-t-s-r-i-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truck-cards-detail-section {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 100px;
  align-self: stretch;
}

.report-table-sales-report-section {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

/*calender*/

.datepicker-top {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.tag {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 0;
  background-color: var(--c-bg-secondary);
  border-radius: 10px;
  padding: 0.5em 0.75em;
  font-weight: 600;
}

.month-selector {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.month-name {
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  height: 24px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.datepicker {
  border-radius: 10px;
  border: 1px solid var(--secondary-color-110);
  padding: 5px;
}

.datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 1rem;
}

.day,
.date {
  justify-self: center;
}

.day {
  color: #393e46;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.main-calender {
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.weekend-day {
  color: #f33 !important;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #291a1a;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
    color: #ff6000;
    border: 2px solid #ff6000;
  }
}

.faded {
  color: #a9a8a8;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.current-day {
  color: #fff;
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: #fff;
  }
}

.report-calender-title {
  color: #393e46;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}

.from-to-title {
  margin: 5px 0px 5px 0px;
  font-size: 13px;
  color: #393e46;
}

button.r-t-f-d-i-i-day {
  display: flex;
  padding: 4px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-Colors-Secondary-Color-100, #a9a8a8);
  background-color: #ffff;
  white-space: nowrap;
  font-size: 16px;
}

.r-t-f-d-i-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.r-f-d-i-i-date>input {
  border-radius: 20px;
  border: 1px solid #a9a8a8;
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.r-f-d-i-i-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-bottom: 10px;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.r-t-f-d-i-title {
  margin: 10px 0px;
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 52px 2px 11px;
}

.r-t-s-r-i-title .form-control {
  display: flex;
  align-items: center;
  gap: 25px;
}

.r-t-s-r-i-title h5 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.report-page h4 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

button.r-t-f-d-i-i-day:hover {
  background-color: #fc2947;
  color: #fff;
  border-color: #fc2947;
}

.r-t-f-d-i-item .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.sales-summary-report-date-title {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 20px;
}

.sales-summary-repor-titles {
  display: flex;
}

.sales-summary-repor-titles {
  gap: 10px;
  align-items: center;
}

p.r-t-f-d-i-i-t-s-t-title-1 {
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

p.r-t-f-d-i-i-t-s-t-title-2 {
  color: #393e46;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.r-t-f-d-i-i-table-sub-title {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.sales-summary-report-titles {
  display: flex;
  gap: 10px;
  align-items: center;
}

.r-t-f-d-i-i-t-s-t-short-date {
  color: #878787;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-record-total-count {
  display: flex;
  gap: 10px;
}

.sales-record-total-count p {
  border-radius: 5px;
  background: #f9f5eb;
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.report-page .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.r-t-s-r-s-item .data-table-body tbody tr td:first-child {
  font-size: 13px;
  display: flex;
  width: 260px;
  padding: 10px 10px;
  align-items: center;
  font-weight: normal;
}

.r-t-s-r-s-item .data-table-body thead tr td {
  font-weight: 500;
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
}

.r-t-s-r-s-item .data-table-body tbody tr td {
  font-size: 13px;
  padding: 15px 10px;
}

.report-page .data-table-body tbody tr:nth-child(even) {
  background-color: unset !important;
}

.report-page .view-more {
  color: #878787;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}

.r-t-s-r-s-item {
  display: flex;
  flex-direction: column;
}

.report-date-dropdowen .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 91px 2px 11px;
}

.click-button {
  background-color: #fc2947 !important;
  color: #fff !important;
  border-color: #fc2947 !important;
}

/* 111 */
/* Dev styles */

.disabled-button {
  opacity: 0.5;
}

.alignment-left-4 {
  width: 100%;
  margin-left: 4rem;
}

.mt-1 {
  margin-top: 5px;
}

.currency-symbol-wrapper-text {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text-align-left {
  text-align: left !important;
}

.qr-instruction-img {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.image-sections {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 210px;
  height: 100%;
}

.image-sections img {
  width: 50px;
  margin-bottom: 1rem;
}

.image-sections p {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.customization-notes {
  color: #878787;
  font-size: 14px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.option-detail-border-unset {
  border: unset !important;
  padding: unset !important;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.qr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-control-adornment input {
  border: transparent;
  border-radius: 5px;
  padding: 7px;
}

.form-control-adornment button {
  background: transparent;
  border: transparent;
}

.social-media-card-row .form-control {
  align-items: center;
  margin-right: 20px;
}

.social-media-card-row .form-control-adornment .s-f-label {
  align-items: center;
  justify-content: space-between;
}

.social-media-card-row .form-control .s-f-label {
  align-items: center;
}

.social-media-card-row .form-control-adornment .b-d-form-action {
  margin: 0px 0px 0px 81.5px;
}

.social-media-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}

.social-media-card-row .form-control>label img {
  margin-right: 20px;
}

.social-media-card-row .form-control .s-f-label>label {
  margin-right: 20px;
}

.social-media-card-row .b-d-form-action a,
.social-media-card-row .b-d-form-action button {
  margin-left: 37px;
}

.form-control-adornment .input,
.form-control-adornment select {
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 35px;
  width: 100%;
  display: flex;
}

.mr-2 {
  margin: 0px 0 0 19px;
}

.form-control>input:focus {
  border: 1px solid var(--primary-color-100);
}

.btn-full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.error-txt {
  color: var(--primary-color-100);
}

.bottom-error-txt {
  color: var(--primary-color-100);
  padding: 10px;
}

.error-msg-div {
  color: var(--primary-color-100);
  font-size: small;
  padding: 2px 3px;
}

.input-error {
  border: 1px solid var(--primary-color-100) !important;
}

.navbar-action div+div {
  margin-left: 20px;
}

.navbar-action {
  cursor: pointer;
}

.logo-wrapper-dev .img1 {
  margin: 0 10px;
  height: 34.245px;
}

.logo-wrapper-dev .img2 {
  height: 29.245px;
}

/* Dev styles */

.menu-saved-items-list {
  overflow-y: auto;
  height: 100%;
}

.menu-saved-item {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.menu-saved-item h5 {
  font-weight: 400;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-saved-item-price {
  min-width: 77px;
}

.menu-saved-item div p {
  color: var(--neutral-color-200);
}

.menu-saved-item>*+* {
  margin-left: 20px;
}

.menu-saved-item .btn-link {
  white-space: nowrap;
}

.menu-saved-item:nth-child(odd) {
  background-color: var(--secondary-color-120);
}

.selection-chips-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.selection-chip {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color-100);
  border-radius: 100px;
  padding: 5px 2px 5px 10px;
}

.selection-chip img {
  width: 20px;
}

.b-d-form-row .table-card-row-wrap {
  width: 100%;
  border: 1px solid var(--secondary-color-110);
  padding: 10px;
  border-radius: 10px;
}

.b-d-form-row .table-card-row-wrap .table-card-row:nth-child(odd) {
  background: #fff;
}

.b-d-form-block .f-c-label-horzontal {
  align-items: center;
  font-size: 16px;
}

.b-d-form-block .f-c-label-horzontal>input {
  color: #a9a8a8;
  font-size: 13px;
  padding: 8px;
  width: 265px;
  height: 35px;
}

.b-d-form-row .b-d-form-row-head {
  margin-bottom: 20px;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox {
  align-items: center;
}

.b-d-form-row .b-d-form-block .b-d-form-checkbox p {
  margin-right: 10px;
}

.b-d-form-row .b-d-form-block .b-d-f-checkbox-2 {
  margin-right: 20px !important;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row {
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-top: 10px;
  border-top: none;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row {
  gap: 15px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row.customization-table-row .t-c-r-title-col input {
  width: 100%;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col>input {
  border-color: #a9a8a8;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-r-input-2 {
  border-color: #a9a8a8;
  border-radius: 4px;
  width: 52px;
  height: 35px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 7px 8px;
}

.b-d-form-row .optiondetail-table .table-card-row-wrap .table-card-row .t-c-r-title-col .t-c-t-optiondetail-doller {
  margin-right: 2px;
  font-size: 16px;
}

.b-d-form-optiondetail-section {
  margin-top: 20px;
}

.b-d-form-block .b-d-form-block-min-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.b-d-form-block .b-d-form-block-min-option:last-child {
  margin-bottom: unset;
}

.b-d-form-block .b-d-form-block-max-option {
  align-items: center;
  justify-content: space-between;
}

.b-d-form-block .b-d-form-block .b-d-form-block-min-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
}

.b-d-form-block .b-d-form-block .b-d-form-block-max-option .form-control>select {
  border-radius: 20px;
  padding: 3px 11px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
}

.side-control-head-title-section .img-fluid {
  width: 25px;
  height: 25px;
}

.side-control-head-title-section {
  align-items: center;
}

.side-control-head-title-section .img-fluid {
  font-size: 26px;
  font-weight: 500;
  margin: 50px 0 30px;
}

.side-control-head-title-section {
  margin: 50px 0 10px;
}

.side-control-head-title-section.menu-preview-side-control {
  justify-content: space-between;
}

.preview-menu .side-control-head-title-section img {
  width: 100%;
}

.table-card-row .t-c-r-title-col-addbtn {
  width: 80px;
  height: 35px;
  border-radius: 30px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}

.table-card-row .t-c-r-title-col .t-c-r-labeltext {
  font-size: 13px;
  color: #393e46;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.r-t-g-sales-table-items {
  border-top: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  border-bottom: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.r-t-g-s-t-i-overview {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.r-t-g-s-t-i-summary {
  display: flex;
  width: 526px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.divider {
  border-radius: 20px;
  background: var(--Secondary-Colors-Secondary-Color-110, #d9d9d9);
  width: 2px;
  align-self: stretch;
}

.horizondal-divider {
  background: #a9a8a8;
  height: 2px;
  align-self: stretch;
}

.report-table-group {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.r-t-g-s-t-i-title {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  color: var(--Primary-Primary-Color-100, #fc2947);
  /* Heading 4 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.r-t-g-s-t-i-t-i-c-info-cards {
  border-radius: 20px;
  border: 1px solid var(--Warning-Color-Warning-Color-200, #ff6000);
  background: var(--Secondary-Colors-Secondary-Color-200, #fff);
  display: flex;
  max-width: 180px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  width: 100%;
  flex: 1 1 48%;
  /* Two cards per row with some margin */
}

.r-t-g-s-t-i-t-i-c-title {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Secondary-Colors-Secondary-Color-120, #f7f7f7);
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 16px 3px 11px;
}

.report-table-sub-title {
  background: var(--Background-Color-Beige, #f9f5eb);
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}

.r-t-s-r-i-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truck-cards-detail-section {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 100px;
  align-self: stretch;
}

.report-table-sales-report-section {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

/*calender*/

.datepicker-top {
  margin-bottom: 1rem;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.tag {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 0;
  background-color: var(--c-bg-secondary);
  border-radius: 10px;
  padding: 0.5em 0.75em;
  font-weight: 600;
}

.month-selector {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.month-name {
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  height: 24px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.datepicker {
  border-radius: 10px;
  border: 1px solid var(--secondary-color-110);
  padding: 5px;
}

.datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 1rem;
}

.day,
.date {
  justify-self: center;
}

.day {
  color: #393e46;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.main-calender {
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.weekend-day {
  color: #f33 !important;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #291a1a;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
    color: #ff6000;
    border: 2px solid #ff6000;
  }
}

.faded {
  color: #a9a8a8;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
}

.current-day {
  color: #fff;
  border-radius: 4px;
  background: #ff6000;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: #fff;
  }
}

.report-calender-title {
  color: #393e46;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}

.from-to-title {
  margin: 5px 0px 5px 0px;
  font-size: 13px;
  color: #393e46;
}

button.r-t-f-d-i-i-day {
  display: flex;
  padding: 4px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-Colors-Secondary-Color-100, #a9a8a8);
  background-color: #ffff;
  white-space: nowrap;
  font-size: 16px;
}

.r-t-f-d-i-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.r-f-d-i-i-date>input {
  border-radius: 20px;
  border: 1px solid #a9a8a8;
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.r-f-d-i-i-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-bottom: 10px;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.report-table-filter-day-section {
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #a9a8a8;
  border-bottom: 1px solid#A9A8A8;
}

.r-t-f-d-i-title {
  margin: 10px 0px;
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 52px 2px 11px;
}

.r-t-s-r-i-title .form-control {
  display: flex;
  align-items: center;
  gap: 25px;
}

.r-t-s-r-i-title h5 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.report-page h4 {
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

button.r-t-f-d-i-i-day:hover {
  background-color: #fc2947;
  color: #fff;
  border-color: #fc2947;
}

.r-t-f-d-i-item .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.sales-summary-report-date-title {
  border-radius: 5px;
  background: #f7f7f7;
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 20px;
}

.sales-summary-repor-titles {
  display: flex;
}

.sales-summary-repor-titles {
  gap: 10px;
  align-items: center;
}

p.r-t-f-d-i-i-t-s-t-title-1 {
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

p.r-t-f-d-i-i-t-s-t-title-2 {
  color: #393e46;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.r-t-f-d-i-i-table-sub-title {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.sales-summary-report-titles {
  display: flex;
  gap: 10px;
  align-items: center;
}

.r-t-f-d-i-i-t-s-t-short-date {
  color: #878787;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-record-total-count {
  display: flex;
  gap: 10px;
}

.sales-record-total-count p {
  border-radius: 5px;
  background: #f9f5eb;
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  color: #393e46;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.report-page .data-table-wrap {
  border: 1px solid var(--secondary-color-100);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.r-t-s-r-s-item .data-table-body tbody tr td:first-child {
  font-size: 13px;
  display: flex;
  width: 260px;
  padding: 10px 10px;
  align-items: center;
  font-weight: normal;
}

.r-t-s-r-s-item .data-table-body thead tr td {
  font-weight: 500;
  color: #393e46;
  font-family: Roboto;
  font-size: 18px;
}

.r-t-s-r-s-item .data-table-body tbody tr td {
  font-size: 13px;
  padding: 15px 10px;
}

.report-page .data-table-body tbody tr:nth-child(even) {
  background-color: unset !important;
}

.report-page .view-more {
  color: #878787;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}

.r-t-s-r-s-item {
  display: flex;
  flex-direction: column;
}

.report-date-dropdowen .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 91px 2px 11px;
}

.click-button {
  background-color: #fc2947 !important;
  color: #fff !important;
  border-color: #fc2947 !important;
}

/* 111 */
/* Dev styles */

.disabled-button {
  opacity: 0.5;
}

.alignment-left-4 {
  width: 100%;
  margin-left: 4rem;
}

.mt-1 {
  margin-top: 5px;
}

.currency-symbol-wrapper-text {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text-align-left {
  text-align: left !important;
}

.qr-instruction-img {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.image-sections {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 210px;
  height: 100%;
}

.image-sections img {
  width: 50px;
  margin-bottom: 1rem;
}

.image-sections p {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.customization-notes {
  color: #878787;
  font-size: 14px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.option-detail-border-unset {
  border: unset !important;
  padding: unset !important;
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

.qr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-control-adornment input {
  border: transparent;
  border-radius: 5px;
  padding: 7px;
}

.form-control-adornment button {
  background: transparent;
  border: transparent;
}

.social-media-card-row .form-control {
  align-items: center;
  margin-right: 20px;
}

.social-media-card-row .form-control-adornment .s-f-label {
  align-items: center;
  justify-content: space-between;
}

.social-media-card-row .form-control .s-f-label {
  align-items: center;
}

.social-media-card-row .form-control-adornment .b-d-form-action {
  margin: 0px 0px 0px 81.5px;
}

.social-media-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}

.social-media-card-row .form-control>label img {
  margin-right: 20px;
}

.social-media-card-row .form-control .s-f-label>label {
  margin-right: 20px;
}

.social-media-card-row .b-d-form-action a,
.social-media-card-row .b-d-form-action button {
  margin-left: 37px;
}

.form-control-adornment .input,
.form-control-adornment select {
  border-radius: 5px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  height: 35px;
  width: 100%;
  display: flex;
}

.mr-2 {
  margin: 0px 0 0 19px;
}

.form-control>input:focus {
  border: 1px solid var(--primary-color-100);
}

.btn-full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.error-txt {
  color: var(--primary-color-100);
}

.bottom-error-txt {
  color: var(--primary-color-100);
  padding: 10px;
}

.error-msg-div {
  color: var(--primary-color-100);
  font-size: small;
  padding: 2px 3px;
}

.input-error {
  border: 1px solid var(--primary-color-100) !important;
}

.navbar-action div+div {
  margin-left: 20px;
}

.navbar-action {
  cursor: pointer;
}

.logo-wrapper-dev .img1 {
  margin: 0 10px;
  height: 34.245px;
}

.logo-wrapper-dev .img2 {
  height: 29.245px;
}

.spinner-div {
  width: 100%;
  text-align: center;
  padding: 1rem;
}

.spinner-div-button {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.spinner-div-button span {
  color: var(--primary-color-100);
  width: 20px !important;
  height: 20px !important;
}

.spinner-div span {
  color: var(--primary-color-100);
}

.empty-div {
  text-align: center;
  background: #f7f7f7;
  padding: 10px;
  margin: 0 10px;
}

.dev-card-actions {
  width: 100%;
  justify-content: space-between !important;
}

.dev-card-actions button {
  width: max-content;
  margin: auto;
}

.dev-card {
  max-width: 600px;
  width: 500px;
  padding: 20px 20px 20px;
}

.dev-disabled {
  background-color: rgb(0 0 0 / 43%);
  border: transparent;
}

.dev-msgPop-msg {
  text-align: center;
  font-weight: 500;
  color: black !important;
  margin-bottom: 5px !important;
}

.dev-msgPop-note {
  text-align: center;
  color: var(--primary-color-100) !important;
}

.menu-link.active img {
  filter: brightness(5);
}

.user-label {
  align-items: center;
  margin-bottom: 10px;
}

.user-label+.user-label {
  margin-bottom: 10px;
}

.user-label>label {
  min-width: 160px;
}

.user-label .u-l-radiobtn {
  width: 20px;
  accent-color: #fc2947;
  margin: 0;
}

.f-c-radiobtn {
  width: 20px;
  accent-color: #fc2947;
  margin: 0;
}

.user-label .mobile-extension>select {
  border-radius: 5px 0px 0px 5px;
  border-right: 0px;
  padding: 2px;
  font-size: 13px;
}

.user-label .mobile-extension>input {
  border-radius: 0px 5px 5px 0px;
  padding: 10px;
  color: #a9a8a8;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.qr-code-table {
  max-width: 1052px;
}

.qr-code-img img {
  height: 200px;
}

.qr-code-img {
  margin: 20px 0;
  border-radius: 10px;
  border: 3px solid var(--Primary-Primary-Color-100, #fc2947);
  padding: 20px;
}

.qr-code-table .qr-code-table {
  max-width: 1052px;
}

.qr-code-table .table-row {
  color: #393e46;
  font-size: 18px;
  font-weight: 500;
}

.qr-code-table .btn-link {
  font-size: 16px;
  font-weight: 700;
  color: var(--neutral-color-200);
  background-color: transparent;
  border: 0;
  font-family: "Lato", sans-serif;
}

.qr-subtitle {
  color: #a9a8a8;
  font-size: small;
  margin-top: 5px;
}

.qr-code-main-page-title {
  font-size: 20px;
  font-weight: 700;
  position: relative;
}

.qr-code-table-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.qr-code-table-card-row+.qr-code-table-card-row {
  border-top: 1px solid var(--secondary-color-110);
}

.qr-code-table-card-row:nth-child(odd) {
  background: var(--secondary-color-120);
}

.qr-code-head-title {
  margin: 10px;
}

.qr-code-table .btn-curve {
  color: #a9a8a8;
}

.qr-name img {
  height: 80px;
  border-radius: 1000px;
  width: 80px;
  border-radius: 100px;
  min-width: 80px;
}

.qr-name {
  align-items: center;
  margin-bottom: 10px;
}

.qr-name .table-row {
  font-size: 30px;
  color: #393e46;
  font-weight: 600;
  margin: 0px 0px 0px 20px;
  justify-content: center;
}

.qr-name .name-code {
  font-size: 18px;
  color: #393e46;
  font-weight: 500;
  margin-left: 20px;
}

.qr-popcard {
  width: 689px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px 20px 40px;
}

.qr-title {
  font-size: 26px;
  color: #393e46;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
}

.qr-instruction {
  font-size: 32px;
  color: #393e46;
  font-weight: 400px;
  text-align: center;
  margin: 10px 0px 20px 0px;
}

.qr-code-card {
  width: 689px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px 20px 40px;
}

.rearrange-catecory-btn {
  background-color: #fc2947;
  border-radius: 10px;
  box-shadow: none;
}

.rearrange-catecory {
  overflow-y: auto;
  padding-right: 10px;
}

.rearrange-catecory-btn p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.rearrange-table-card-row {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.rearrange-table-card-row p {
  color: var(--primary-color-200);
}

.rearrange-table-card-row .c-title,
.selct-customization .c-title {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-title.unset-whitespace {
  white-space: normal;
}

.food-truck-side-main .form-control select {
  padding: 4px 0 2px 11px;
}

.rearrange-catecory-sub-title {
  color: #878787;
  font-size: 16px;
  margin-bottom: 30px;
}

.rearrange-table-card {
  flex-direction: column;
  border: 1px solid var(--secondary-color-110);
  border-radius: 20px;
}

.rearrange-table-card+.rearrange-table-card {
  margin-top: 20px;
}

.rearrange-category-overflow {
  max-height: 757px;
  overflow: auto;
}

.preview-menu {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  width: 375px;
  height: calc(100vh - 180px);
  border-radius: 20px;
  border-left: none;
  background-color: #fff;
}

.preview-b-d-form-block {
  justify-content: center;
  display: flex;
}

.preview-head-title {
  background-color: #f7f7f7;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  padding: 10px;
  margin: 10px 0;
}

.preview-head-title p {
  color: #393e46;
}

.Varieties-title {
  background-color: #f7f7f7;
  font-size: 20px;
  font-weight: 600;
  margin: 10px;
  font-family: "Lato", sans-serif;
}

.Varieties-title p {
  color: #393e46;
}

.menu-list {
  margin: 10px;
}

.menu-items-title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
  flex-direction: column;
  margin-top: 10px;
}

.menu-sub-title {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #a9a8a8;
  margin-top: 5px;
}

.e-details {
  color: #878787;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-top: 11.5px;
}

.e-details div {
  color: #fc2947;
  cursor: pointer;
}

.menu-sub-title div {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.menu-sub-title.full-view div {
  -webkit-line-clamp: unset;
}

.menu-add-btn button {
  border: 2px solid #393e46;
  color: #393e46;
  background-color: #fff;
  padding: 4.5px 40px;
  margin: 10px 0px;
}

.preview-content-wrapper {
  overflow: hidden;
  width: 100%;
}

.preview-side-scrollbar {
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 325px);
  padding-right: 15px;
  box-sizing: content-box;
}

.preview-menu .side-control-head-title-section img {
  width: 100%;
  height: auto;
}

.menu-item-img {
  width: 125px;
  height: 70px;
}

.menu-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select-menu-dropdown .MuiMenu-paper {
  box-shadow: 2px 2px 10px 0px #0000001c;
  border-radius: 20px;
}

.select-menu-dropdown .MuiMenu-list {
  padding: unset;
}

.select-menu-dropdown .MuiMenuItem-root {
  padding: 11px 20px;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 500;
  color: #878787;
}

.select-menu-dropdown .MuiMenuItem-root:last-child {
  border: unset;
}

.menu-add-letter {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.from-to-title p {
  font-size: 16px;
  color: #393e46;
  margin-bottom: 10px;
}

.customize-mui-calender .MuiDateCalendar-root {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--c-bg-secondary);
  padding: 5px;
}

.customize-mui-calender .MuiPickersCalendarHeader-root {
  position: relative;
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiButtonBase-root:not(.Mui-disabled) svg {
  color: #393e46;
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label {
  background: #ff6000;
  color: #fff;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label {
  margin: unset;
}

.customize-mui-calender .MuiDayCalendar-header .MuiTypography-caption {
  color: #393e46;
}

.customize-mui-calender .MuiDayCalendar-header .MuiTypography-caption:first-child,
.customize-mui-calender .MuiDayCalendar-header .MuiTypography-caption:last-child {
  color: #ff3333;
}

.customize-mui-calender .MuiPickersDay-root:first-child,
.customize-mui-calender .MuiPickersDay-root:last-child {
  color: #ff3333;
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiButtonBase-root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiIconButton-edgeEnd {
  left: 0;
}

.customize-mui-calender .MuiPickersCalendarHeader-root .MuiIconButton-edgeStart {
  right: 0;
}

.customize-mui-calender .MuiPickersCalendarHeader-labelContainer {
  margin: auto;
}

.customize-mui-calender .MuiDayCalendar-header,
.customize-mui-calender .MuiDayCalendar-weekContainer {
  justify-content: space-between;
}

.customize-mui-calender .MuiDayCalendar-weekContainer .MuiButtonBase-root {
  line-height: unset;
  border-radius: 4px;
}

.customize-mui-calender .MuiDayCalendar-weekContainer .MuiButtonBase-root.Mui-selected {
  background-color: #ff6000;
  color: #fff;
}

input[type="checkbox"] {
  accent-color: #fc2947;
}

.min-h-err-msg {
  min-height: 20px;
}

.price-pad-left {
  padding-left: 12px;
}

.gst-flex {
  justify-content: flex-start !important;
}

.form-gst-percentage input {
  width: 60px !important;
}

.form-gst-percentage p {
  padding-left: 5px;
}

.country-select-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.country-select-main select {
  width: max-content !important;
}

.food-truck-preview-img img {
  height: 50px;
}

.form-flex-center {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.wid-calc-input {
  width: calc(100% - 170px) !important;
}

.form-flex-center label {
  margin-bottom: unset !important;
}

.border-unset {
  border: unset !important;
  padding: unset !important;
}

.user-role-subtitle {
  color: #a9a8a8;
  padding-top: 5px;
}

.name-input-field {
  width: 238px;
}

.name-input-field input {
  width: 100%;
}

.c-spicelevel,
.c-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.side-controls .table-card-row-wrap {
  max-height: calc(100vh - 360px);
  min-height: calc(100vh - 500px);
  height: 100%;
  overflow: auto;
}

.preview-main-side {
  justify-content: flex-start;
}

.preview-main-side .side-control-head-title-section {
  margin: 0px 0 30px;
}

/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 1px solid var(--secondary-color-110);
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a8a8;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDrawer-modal .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.MuiPaper-elevation {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.24) !important;
}

.s-f-label .error-msg-div {
  position: absolute;
}

.form-control-adornment .s-f-label button {
  align-items: center;
}

.form-control-adornment .s-f-label button img {
  position: relative;
  top: 1px;
}

.social-media-card-row .input {
  width: 250px;
}

.menu-details .menu-list .menu-items {
  justify-content: space-between;
}

.dashboard-data .form-control select {
  padding: 0 5px;
  min-height: 30px;
}

.file-upload-logo {
  position: relative;
  overflow: hidden;
}

.zoom-comp {
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-Colors-Secondary-Color-100, #a9a8a8);
  background: var(--Secondary-Colors-Secondary-Color-200, #fff);
  padding: 2px 5px;
  width: max-content;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 13px;
  margin: auto;
  z-index: 1;
}

.zoom-comp button {
  border: unset;
  background: unset;
  padding: unset;
}

.zoom-comp button img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 1px;
}

.zoom-comp span {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.border-remv {
  border: unset !important;
}

.option-lable-txt {
  font-size: 13px;
  color: #393e46;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  padding-bottom: 5px;
  display: block;
}

.price-label {
  display: block;
  padding-left: 10px;
}

.ml-10px {
  margin-left: 10px;
}

.master-guide-image-popup .close-section {
  margin: unset;
}

.master-guide-image-popup .close-section .btn-icon {
  padding: unset;
}

.close-align-btn {
  margin: unset;
}

.close-align-btn button {
  padding: unset;
}

.help-flex {
  align-items: center;
}

.help-flex p {
  font-size: 16px;
  font-weight: 500;
}

.help-flex p:hover {
  color: #fc2947;
}

.number-validation {
  color: var(--Neutral-Color-Neutral-Color-200, #878787);
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.danger-validation {
  color: var(--Warning-Color-Warning-Color-100, #f33);
  font-family: Roboto;
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.home-help a {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.2s all;
}

.home-help a:hover {
  color: #fc2947;
  transition: 0.2s all;
}

.otp-label {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otp-label span {
  color: var(--Neutral-Color-Neutral-Color-100, #393e46);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.item-name-max-width {
  max-width: 150px;
}

.horiz-validation {
  align-items: flex-start !important;
}

.horiz-validation label {
  height: 35px;
  margin-bottom: unset;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  /* For Safari */
  width: 20px;
  height: 20px;
  border: 1px solid var(--Neutral-Color-Neutral-Color-100, #393e46);
  background-color: white;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
}

/* Create custom red tick */
input[type="checkbox"]:checked::before {
  content: "";
  background: url(/src/assets/img/tick-icon.png) no-repeat;
  color: #fc2947;
  font-size: 17px;
  position: absolute;
  top: 48%;
  left: 2px;
  width: 100%;
  height: 10px;
  background-size: contain;
  transform: translateY(-50%);
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #393e46;
  /* Black border */
  border-radius: 100px;
  background-color: white;
  /* White background */
  cursor: pointer;
}

input[type="radio"]:disabled {
  pointer-events: none;
  opacity: 0.3;
}

/* The red dot when the radio button is checked */
input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  background-color: #fc2947;
  /* Red dot */
}

.max-400px {
  max-width: 400px;
  width: 400px;
  white-space: normal;
  padding-right: 75px !important;
}

.gap-10px {
  gap: 10px;
}

.align-center {
  align-items: center;
}

.r-t-s-r-i-title .form-control .pointer {
  position: relative;
  right: 10px;
}

.form-control select {
  padding: 4px 11px !important;
}

.report-page .form-control input,
.form-control select {
  display: block;
  border-radius: 32px;
  border: 1px solid var(--secondary-color-100);
  background: #fff;
  display: flex;
  padding: 4px 52px 2px 11px;
}

.report-table-sales-report-section .r-t-s-r-i-title .form-control h5 {
  position: relative;
  left: 10px;
}

.option-detail-border-unset {
  min-height: unset !important;
}

.remv-padd {
  padding: unset !important;
}

.spicelevel-main .c-spicelevel {
  display: block;
  width: 220px;
  overflow: auto;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  word-break: break-word;
}

textarea {
  font-family: Roboto, sans-serif !important;
  font-size: 16px;
}

.user-role-added.data-table-body table tbody tr td {
  vertical-align: middle;
}

.user-role-added.data-table-body table tbody tr td:first-child {
  min-height: 65px;
}

.social-media-card-row .btn-primary:hover * {
  color: #fff !important;
}

/* About Us Page BEGIN*/
.s-m-banner-img {
  width: 100%;
  height: 580px;
}

.s-m-banner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.s-m-wrapper {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.static-page-title {
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.static-main .container {
  max-width: 1200px;
  margin: auto;
}

.s-m-content p,
.s-m-content li {
  color: #393e46;
  text-align: justify;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

.s-m-content ol {
  padding-left: 22px;
  list-style-position: inside;
}

.s-m-content li {
  margin-bottom: unset;
}

.s-m-content p:last-child {
  margin-bottom: unset;
}

.s-m-content .mb-unset-0 {
  margin-bottom: unset;
}

.s-m-content .highlight-txt {
  color: #fc2947;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.s-m-content .txt-indent {
  text-indent: 100px;
}

.s-m-content .txt-indent-sm {
  text-indent: 35px;
}

/* About Us Page END*/

/* Terms Page BEGIN */
.terms-main .s-m-content li {
  margin-bottom: 20px;
}

.address-box {
  margin-top: 30px;
}

.address-box p {
  margin-bottom: unset;
}

/* Terms Page END */

/* Privacy BEGIN */
.privacy-main .s-m-content ol li {
  margin-bottom: 30px;
}

.privacy-main .s-m-content ol li p {
  margin-top: 20px;
}

/* Privacy END */

/* Help Page BEGIN */
.h-m-title-box {
  background: #f7f7f7;
  padding: 40px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-m-title {
  color: #fc2947;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.h-m-title-box button {
  border: unset;
  padding: unset;
  background: unset;
  margin: unset;
}

.h-m-wrapper {
  display: flex;
}

.h-m-left {
  width: 40%;
  background-color: #2d2d2d;
  color: #d9d9d9;
  height: calc(100vh - 215px);
}

.h-m-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.h-m-l-flex {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: max-content;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.h-m-l-title {
  color: #d9d9d9;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.h-m-mail {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.h-m-mail a {
  color: #d9d9d9;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Help Page END */
.body-wrapper {
  height: 100vh;
}

.navbar-action.home-help {
  position: relative;
}

.help-popup {
  border-radius: 10px;
  background: #f7f7f7;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: absolute;
  width: max-content;
  right: 0;
  top: calc(100% + 5px);
}

.help-popup span {
  color: #393e46;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.help-popup .highlight-txt {
  color: #fc2947;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.b-d-form-row>.form-control.horizontal-form {
  width: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 10px;
}

.price-form-input input {
  max-width: 80px;
}

.form-width-shrink {
  width: 70%;
}

/* Dev styles */

@media (max-width: 1400px) {

  .hero-section .container,
  .features-section .container {
    padding: 0px 5%;
  }

  .howworks-steps p {
    font-size: 20px;
  }
}

@media (max-width: 1300px) {
  .howworks-steps div img {
    height: 100px;
  }
}

@media (max-width: 1200px) {
  .features-card {
    width: calc(100% / 3.5);
  }

  .features-card-img {
    height: 200px;
  }

  .menu-side.active {
    display: flex;
    position: fixed;
    background: #fff;
    z-index: 99;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1200px) {
  .ham-menu-icon {
    display: none;
  }

  .ham-menu-icon.active {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .hero-section .container {
    flex-direction: column-reverse;
  }

  .welcome-msg-wrap {
    margin-bottom: 30px;
  }

  .callout-text p {
    font-size: 20px;
  }

  .dashboard-data-card-details {
    flex-direction: column;
  }

  .d-d-card {
    width: 100%;
  }

  .dashboard-data-card-title {
    flex-direction: column;
    gap: 5px;
  }

  .dashboard-nodata {
    justify-content: center;
  }

  .dashboard-nodata .features-card {
    width: 100%;
    margin-bottom: 20px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .social-media-card-row .form-control-adornment .b-d-form-action {
    margin: 0;
  }

  .social-media-card-row .input {
    width: 200px;
  }

  .social-media-card-row .input input {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .h-m-title-box {
    padding: 20px 30px;
  }

  .h-m-wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .h-m-left {
    width: 100%;
  }

  .s-m-banner-img {
    height: auto;
    aspect-ratio: 16/9;
  }
}

@media (max-width: 768px) {

  .sales-summary-report-titles,
  .r-t-f-d-i-i-t-s-t-short-date {
    flex: 1 1 100%;
    text-align: left;
  }

  .r-f-d-i-i-date {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
  }

  .form-control {
    align-items: flex-start;
  }

  .truck-cards-detail-section {
    flex-wrap: wrap;
    width: calc(94% - 20px);
    gap: 51px;
  }

  .calltoaction-wrap {
    width: 100%;
  }

  .features-card {
    width: 100%;
  }

  .features-section .container {
    flex-wrap: wrap;
  }

  .features-card+.features-card {
    margin-top: 20px;
  }

  .features-card-img {
    text-align: center;
  }

  .howworks-section {
    margin-top: 100px;
  }

  .calltoaction-section {
    padding: 100px 30px;
  }

  .calltoaction-wrap {
    flex-direction: column;
    text-align: center;
  }

  .calltoaction-wrap a {
    margin-top: 20px;
  }

  .welcome-msg-wrap {
    width: 100%;
  }

  .footer-copyright img {
    height: 50px;
  }

  .footer-copyright p {
    font-size: 20px;
  }

  .b-d-form-row {
    flex-direction: column;
  }

  .b-d-form-row>.form-control {
    width: 100%;
  }

  .b-d-form-row>.form-control+.form-control {
    margin-left: 0;
    margin-top: 10px;
  }

  .dashboard {
    flex-direction: column;
    align-items: center;
  }

  .setup-process {
    margin: 0;
    width: 100%;
  }

  .dashboard-data-card-wrap {
    width: 100%;
    margin-bottom: 30px;
  }

  .file-upload-action.d-flex {
    flex-direction: column;
  }

  .file-upload-action .btn-primary+.btn-primary {
    margin-top: 10px;
    margin-left: 0;
  }

  .side-controls {
    left: 0;
    width: 100%;
  }

  .b-d-form-row h4 {
    margin-bottom: 10px;
  }

  .wid-calc-input {
    width: 100% !important;
  }

  .form-flex-center {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 650px) {
  .preview-head-title {
    font-size: 17px;
  }

  .callout-section {
    flex-direction: column;
  }

  .truck-cards-detail-section {
    gap: 50px;
  }

  .callout-img {
    width: 100%;
  }

  .callout-text {
    width: 100%;
    padding: 70px 30px;
  }

  .howworks-steps {
    flex-direction: column;
  }

  .howworks-steps>img {
    transform: rotate(90deg);
    margin: 30px 0;
  }

  .calltoaction-wrap {
    border-radius: 10px;
  }

  .footer-links {
    flex-direction: column;
    padding: 40px 10%;
  }

  .footer-links>div+div {
    margin: 20px 0 0 0;
  }

  .footer-links a+a {
    margin-top: 20px;
  }

  .footer-links a {
    font-size: 20px;
  }

  .otp-card {
    width: 100%;
  }

  .otp-section {
    padding: 20px;
  }

  .otp-input input {
    width: 42px;
    height: 50px;
    font-size: 20px;
  }

  .business-details-form {
    width: 100%;
  }

  .navbar-action {
    flex-direction: column;
  }

  .navbar-action a+a {
    margin: 5px 0 0 0;
    justify-content: flex-end;
  }

  .hide-mobile {
    display: none;
  }

  .navbar-section {
    padding: 19px 20px;
  }

  .main-body {
    padding: 20px 20px;
  }

  .table-card-row {
    flex-direction: column;
  }

  .table-card-row>* {
    margin-top: 10px;
  }

  .social-media-card-row .form-control-adornment .s-f-label {
    justify-content: center;
  }

  .social-media-card-row .form-control,
  .social-media-card-row .form-control-adornment {
    flex-direction: column;
    width: 100%;
  }

  .social-media-card-row .form-control input img {
    width: 100%;
  }

  .social-media-card-row .form-control .s-f-label input {
    width: 100%;
  }

  .social-media-card-row .form-control .b-d-form-action,
  .social-media-card-row .form-control-adornment .b-d-form-action {
    margin: 0px;
    width: 100%;
  }

  .side-control-head-title-section h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .side-control-head-title-section {
    flex-wrap: wrap;
    margin: 10px 0px 10px 0px;
  }
}

@media screen and (max-width: 575px) {
  .help-popup {
    width: 300px;
  }

  .h-m-l-flex {
    width: 100%;
    padding: 0 20px;
  }

  .h-m-left {
    height: calc(100vh - 460px);
  }

  .h-m-right img {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding: 20px;
  }

  .s-m-wrapper {
    padding: 15px;
    gap: 30px;
  }

  .static-page-title {
    font-size: 25px;
  }

  .s-m-content p,
  .s-m-content li {
    font-size: 19px;
    margin-bottom: 20px;
  }
}

@media (max-width: 374px) {
  .report-table-sub-title .form-control select {
    padding: 4px 20px 2px 11px;
  }

  .navbar-section .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .navbar-section .container>* {
    width: 100%;
  }

  .full-page .navbar-section {
    height: auto;
  }

  .navbar-section .navbar-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* Update the styles for Get OTP button */
.signin-form button.btn-primary.dev-disabled {
  background-color: rgb(0 0 0 / 43%);
  border: transparent;
}

.signin-form button.btn-primary.dev-disabled:hover {
  background-color: rgb(0 0 0 / 43%) !important;
  border-color: transparent !important;
  cursor: not-allowed;
}